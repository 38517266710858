import React from 'react';
import data from '../data/pages/products.json';
import { Layout, Container, ProductFeed, ServiceFeed, Disclaimer } from '../components';

const InvestmentProducts = () => {
  return (
    <Layout pageContext={data}>
      <Container addSpacers>
        {/* Product Feed */}
        <ProductFeed
          columnCount={3}
          headerProps={{
            title: 'Our Featured Products',
            text:
              'Price fluctuates depending on market values, please reach out for quote.',
          }}
        />

        {/* Disclaimer */}
        <Disclaimer />

        {/* Service List */}
        <ServiceFeed exclude={['investment-products']} scrollOnMobile />
      </Container>
    </Layout>
  );
};

export default InvestmentProducts;
